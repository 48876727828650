<template>
  <main-layout>
    <div class="row align-items-center justify-content-between">
      <h1 class="w-auto m-0">{{ $t("sidebarCompanyBtn") }}</h1>
      <div class="servicesPage__searchInput">
        <search-input
          v-model="search"
          :search-function="searchCompanies"
          @keyup.enter="searchCompanies"
          :placeholder="$t('findCompany')"
        />
      </div>
      <div class="mt-30 d-flex justify-content-between align-items-center">
        <div class="employee__recent-sort">
          <!--          <div class="mr-15">{{ $t('employeeHomePageSort') }}</div>-->
          <!--          <base-button @click="sortBy('data')" :active=sort.data :sort=true>-->
          <!--            {{ $t('dataAdded') }}-->
          <!--          </base-button>-->
          <!--          <base-button @click="sortBy('employee')" :active=sort.employee :sort=true>-->
          <!--            {{ $t('employeesAmount') }}-->
          <!--          </base-button>-->
        </div>
        <div class="d-flex align-items-center">
          <div class="me-10">
            {{ $t("view") }}
          </div>
          <div class="me-10 cursor-pointer" @click="changeView('table')">
            <table-view-icon :active="view.table" />
          </div>
          <div class="me-10 cursor-pointer" @click="changeView('cart')">
            <cart-view-icon :active="view.cart" />
          </div>
        </div>
      </div>
      <div v-if="!!companies && companies.length > 0">
        <div v-if="view.cart" class="mt-20 w-100 row">
          <div
            class="col-12 col-lg-6"
            v-for="company in companies"
            :key="company.user_id"
          >
            <company-cart :company-information="company" />
          </div>
          <div
            v-if="companies && companies.length === 10"
            class="w-max-content m-auto"
          >
            <base-button class="loadMoreBtn" @click="loadMore()" :load="true"
              >{{ $t("loadMore") }}
            </base-button>
          </div>
        </div>
        <div v-if="view.table">
          <div class="row mt-20 transactionsPage__tableTitles">
            <div class="col-5">
              <div>{{ $t("Company") }}</div>
            </div>
            <div class="col-4">
              <div>{{ $t("fieldOfActivity") }}</div>
            </div>
            <div class="col-3">
              <div>{{ $t("sidebarEmployeesBtn") }}</div>
            </div>
          </div>
          <company-table
            v-for="company in companies"
            :key="company.user_id"
            :company-information="company"
          />
          <div
            v-if="companies && companies.length === 10"
            class="w-max-content m-auto"
          >
            <base-button class="loadMoreBtn" @click="loadMore()" :load="true"
              >{{ $t("loadMore") }}
            </base-button>
          </div>
        </div>
      </div>
      <div v-else class="mt-30">
        <h5>{{ $t("noFoundResult") }}</h5>
        <p class="mt-10">{{ $t("tryAnother") }}</p>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import SearchInput from "@/components/UI/inputs/SearchInput";
import BaseButton from "@/components/UI/buttons/BaseButton";
import CartViewIcon from "@/components/UI/icons/buttonIcons/CartViewIcon.vue";
import TableViewIcon from "@/components/UI/icons/TableViewIcon";
import CompanyTable from "@/components/elements/card/CompanyTable";
import CompanyCart from "@/components/elements/card/CompanyCart";
import { mapGetters } from "vuex";

export default {
  name: "CompaniesPage",
  components: {
    CompanyCart,
    CompanyTable,
    TableViewIcon,
    CartViewIcon,
    BaseButton,
    SearchInput,
    MainLayout,
  },
  data() {
    return {
      sort: {
        data: true,
        employee: false,
      },
      services: [],
      filter: [],
      filterServices: [],
      viewLimit: 10,
      viewOffset: 0,
      search: "",
    };
  },
  mounted() {
    if (!this.companies) this.getCompanies();
  },
  methods: {
    sortBy(key) {
      for (let i in this.sort) {
        this.sort[i] = i === key;
      }
      if (key === "data") {
        const reverse = this.companies.reverse();
        this.$store.commit("SET_COMPANIES_ITEMS", reverse);
      }
    },
    getCompanies(searchFilter) {
      let search = searchFilter ? searchFilter : null;
      this.$http
        .get("/companies/raw", {
          params: {
            search: search,
            limit: this.limit,
          },
        })
        .then(({ data }) => {
          this.$store.commit("SET_COMPANIES_ITEMS", data.body);
        });
    },
    changeView(key) {
      const view = {};
      for (let i in this.view) {
        view[i] = i === key;
      }
      this.$store.commit("SET_COMPANIES_VIEW", view);
    },
    searchCompanies() {
      this.getCompanies(this.search);
    },
    loadMore() {
      this.limit = this.limit + 10;
      this.getCompanies(this.search);
    },
  },
  computed: {
    ...mapGetters({
      companies: "COMPANIES_ITEMS",
      view: "COMPANIES_VIEW",
    }),
  },
};
</script>

<style></style>
