<template>
  <router-link
    :to="/company/ + companyInformation.code"
    class="row companyTable"
    :class="{ 'blocked-user': !!companyInformation.is_blocked }"
  >
    <div class="col-5 d-flex align-items-center">
      <company-logo
        :thumbnail="true"
        :company_slug="companyInformation.code"
        :image-name="companyInformation.logo"
        size="150"
      />
      <div class="ml-30">
        <div class="companyCart__name">{{ companyInformation.name }}</div>
        <div class="companyCart__code">{{ companyInformation.code }}</div>
      </div>
    </div>
    <div class="col-4 d-flex align-items-center">
      {{ companyInformation.activity }}
    </div>
    <div class="col-2 d-flex align-items-center">
      {{ companyInformation.employees_count }} {{ $t("employees") }}
    </div>
    <div class="col-1 d-flex justify-content-end align-items-center">
      <arrow height="16px" width="16px" />
    </div>
  </router-link>
</template>

<script>
import CompanyLogo from "@/components/UI/images/CompanyLogo";
import Arrow from "@/components/UI/icons/arrows/Arrow";

export default {
  components: { Arrow, CompanyLogo },
  props: {
    companyInformation: Object,
  },
  name: "CompanyTable",
};
</script>

<style></style>
